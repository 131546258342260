import React, { Component } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Component Import
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import TitleUnderlined from '../components/TitleUnderlined';
import ButtonUnderlined from '../components/ButtonUnderlined';
import Squiggle from '../components/squiggle';
import linkStripper from '../utils/link-stripper';
import LazyImage from '../components/lazy-image';

//Asset Import
import Image from '../images/tmp/expertiseSubPageHeader.png';
import convertHtml from '../utils/convertHtml';

const SubpageHeaderContentOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  max-height: 800px;
  overflow: hidden;
  max-height: unset;
  @media (max-width: 1050px) {
    height: unset;
    min-height: 1000px;
  }
`;

const SubpageHeaderContentInner = styled.div`
  width: 100%;
  max-width: 1400px;
`;

const SubPageHeaderContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 160px);
  max-height: 1200px;
`;

const SubPageHeaderContainer = styled.div`
  width: 100%;
`;

const SubPageHeaderContentContainer = styled.div`
  width: 60%;
  margin-left: 5%;
  @media (min-width: 1400px) {
    width: 70%;
    margin-left: 0%;
  }
  @media (min-width: 1000px) {
    width: 55%;
  }
  @media (max-width: 960px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const TextContainer = styled.div`
  width: 575px;
`;

const SubHeading = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: 'DM Sans';
  color: #404040;
  @media (max-width: 960px) {
    font-size: 16px;
  }
`;

const ParagraphText = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  color: #404040;
  max-width: 700px;
  font-family: 'DM Sans';
  span {
    color: #4758eb;
    text-decoration: underline;
  }

  strong,
  b {
    font-weight: bold;
  }

  ul {
    list-style: outside;
    padding-left: 20px;
    list-style-type: square;
  }

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const ImageBlock = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    position: relative;
    display: flex;
    width: 100%;
    display: none;
    justify-content: center;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  margin-top: 150px;
  @media (min-width: 1400px) {
    width: 600px;
    height: 555px;
    margin-top: 0px;
  }
  width: 500px;
  height: 465px;
  @media (max-width: 1200px) {
    margin-top: 50px;
  }
  @media (max-width: 1100px) {
    width: 400px;
  }
  @media (max-width: 960px) {
    height: 360px;
    width: 310px;
  }
`;

const RightImage = styled.img``;

const SquiggleContainer = styled.div`
  position: absolute;
  display: none;
  bottom: 300px;
  right: 0;
  top: 0;
  margin-top: 25%;
  z-index: 2;
  margin-right: -100px;
  mix-blend-mode: multiply;
  @media (max-width: 960px) {
    display: none;
  }
`;

const TextContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1360px) {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const SubpageContentContainer = styled.div`
  display: flex;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`;

const SubpageContentContainerLeft = styled(motion.div)`
  width: 50%;
  @media (max-width: 1050px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const SubpageContentContainerRight = styled(motion.div)`
  width: 50%;
  @media (max-width: 1050px) {
    width: 100%;
  }
`;

const ImageConatinerOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ImageConatiner = styled.div`
  height: 600px;
  width: 600px;
  @media (max-width: 1360px) {
    height: 500px;
    width: 500px;
  }
`;

const rightAnim = {
  hidden: { opacity: 0, translateX: 50 },
  visible: {
    opacity: 1,
    translateX: 0,
  },
};

const leftAnim = {
  hidden: { opacity: 0, translateX: -50 },
  visible: {
    opacity: 1,
    translateX: 0,
    transition: {
      delay: 0.15,
    },
  },
};

export class SubpageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={350}
      >
        <SubpageHeaderContentOuter>
          <SubpageHeaderContentInner>
            <SubpageContentContainer>
              <SubpageContentContainerLeft
                variants={leftAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <TextContentContainer>
                  <TextContainer>
                    {this.props.label_text && (
                      <Label text={this.props.label_text} color="#4758eb" />
                    )}
                    <BlankSpace height="40px" />
                    <TitleUnderlined
                      titleText={'<span>' + this.props.title_text + '</span>'}
                      textColor={this.props.title_color}
                      underlineColor={this.props.title_underline_color}
                      fontSize="40px"
                      lineHeight="48px"
                      mobileFontSize="40px"
                      mobileLineHeight="48px;"
                      h1Mode={true}
                      leftAligned={true}
                    />
                    {this.props.subtitle_text && (
                      <>
                        <BlankSpace height="30px" />
                        <SubHeading
                          dangerouslySetInnerHTML={convertHtml(
                            this.props.subtitle_text
                          )}
                        />
                      </>
                    )}
                    <BlankSpace height="30px" />
                    <ParagraphText
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.paragraph_text
                      )}
                    ></ParagraphText>
                    {this.props.cta_text && (
                      <>
                        <BlankSpace height="80px" mobileHeight="40px" />
                        <ButtonUnderlined
                          buttonText={this.props.cta_text}
                          link={linkStripper(
                            this.props.call_to_action_link.url
                          )}
                          color={this.props.call_to_action_color}
                          target={this.props.call_to_action_link_target}
                        />
                      </>
                    )}
                  </TextContainer>
                </TextContentContainer>
              </SubpageContentContainerLeft>
              <SubpageContentContainerRight
                variants={rightAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ImageConatinerOuter>
                  <ImageContainer>
                    {this.props.right_aligned_image && (
                      <LazyImage {...this.props.right_aligned_image} />
                    )}
                  </ImageContainer>
                </ImageConatinerOuter>
              </SubpageContentContainerRight>
            </SubpageContentContainer>
          </SubpageHeaderContentInner>
        </SubpageHeaderContentOuter>
      </VisibilitySensor>
    );
  }
}

export default SubpageHeader;
